// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-myths-js": () => import("./../src/pages/myths.js" /* webpackChunkName: "component---src-pages-myths-js" */),
  "component---src-pages-tips-js": () => import("./../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */),
  "component---src-pages-workingeffectively-js": () => import("./../src/pages/workingeffectively.js" /* webpackChunkName: "component---src-pages-workingeffectively-js" */)
}

